<template>
  <a-layout class="yj-conten-div">
    <a-layout-content>
      <div
        class="yj-conten"
        style="background:#fff;padding:20px 20px 0;min-height:calc(100% - 48px)"
      >
        <a-row class="yj-form"></a-row>
        <a-table
          bordered
          :columns="columns"
          rowKey="ID"
          :data-source="tableData"
          :pagination="pagination"
          @change="pagerChange"
          class="yj-table-img"
        >
          <span slot="imgs" slot-scope="text,record">
            <img class="imgPreview" :src="record.StoreImg" />
          </span>
           <!-- <span slot="action" slot-scope="record">
              <a  style="color:red;" v-if="record.QRMaterialBindType==2">未绑定物料</a>
              <a  v-if="record.QRMaterialBindType == 1" >已绑定物料</a>
              <a  v-if="record.QRMaterialBindType == 0" >已绑定摇一摇活动</a>
            </span> -->
        </a-table>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import http from "@/Plugin/Http.js";
import util from "@/Plugin/util.js";
import local from "@/Plugin/local.js";
export default {
  name: "",
  data() {
    return {
      ID: "",
      StartNum: "",
      EndNum: "",
      Time: [],
      BeginTime: "",
      EndTime: "",
      tableData: [],
      pagination: {
        total: 0,
        defaultPageSize: 0,
        current: 1,
        showTotal: total => `共 ${total} 条数据`
      },
      columns: [
        {
          title: "门店名称",
          width: 150,
          dataIndex: "StoreName"
        },
        {
          title: "门店图片",
          align: "center",
          scopedSlots: { customRender: "imgs" },
          width: 100
        },

        {
          title: "备注",
          width: 200,
          dataIndex: "Retarks"
        },
        {
          title: "地址",
          width: 200,
          dataIndex: "BindAddress"
        },
        {
          title: "名称",
          width: 200,
          dataIndex: "BindName"
        },
        {
          title: "手机",
          width: 100,
          dataIndex: "BindPhone"
        },
        {
          title: "浏览量",
          width: 80,
          dataIndex: "VisitCount"
        },
        {
          title: "邀请人数",
          width: 100,
          dataIndex: "InviteCount"
        },
        {
          title: "奖励金额",
          width: 80,
          dataIndex: "AwardMoney"
        },
        {
          title: "绑定时间",
          width: 150,
          dataIndex: "BindTime",
          customRender: (text, item) => {
            if (item.BindTime != null) {
              return util.TimeStamptoDateTime( item.BindTime, "yyyy-MM-dd hh:mm:ss");
            } 
            else {
              return "";
            }
          }
        }
        //  ,{
        //   title: "物料绑定",
        //   width: 300,
        //   scopedSlots: { customRender: "action" }
        // }
      ]
    };
  },
  props: {
    //组件属性
  },
  methods: {
    //方法

    getTableData: function() {
      var self = this;
      self.StartNum = self.$route.query.StartNum;
      self.EndNum = self.$route.query.EndNum;
      var op = {
        url: "/MarketingModule/Promote/GetTurnTableQRMatieralList",
        data: {
          pageIndex: self.pagination.current,
          StartNum: self.StartNum,
          EndNum: self.EndNum
        },
        OnSuccess: function(data) {
          self.tableData = data.data.Data;
          self.pagination.total = data.data.Total;
          self.pagination.defaultPageSize = data.data.RowCount;
          self.pagination.current = data.data.PageIndex;
          //console.log(self.tableData);
        }
      };
      http.Post(op);
    },

    pagerChange: function(item) {
      var self = this;
      self.pagination.current = item.current;
      self.getTableData();
    },
    // BindQRMaterial:function(e){//物料绑定
    //   var self = this;
    //   self.$router.push({
    //     name: "PromoteMaterial_BindMaterial",
    //     query: { 
    //         QRMaterialID:e.QRMaterialID,
    //         StartNum:self.StartNum,
    //         EndNum:self.EndNum
    //     }
    //   });
    // }

  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    //生命周期，创建完成
  },
  beforeMount: function() {
    //生命周期，组装前
  },
  mounted: function() {
    //生命周期，组装完成
    var self = this;
    self.getTableData();
    local.clearlocal();
    self.ID = self.$route.query.ID;
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
  },
  destroyed: function() {
    //生命周期，销毁完成
  }
};
</script>
<style scoped>
.imgPreview {
  border-radius: 4px;
  width: 50px;
  height: 50px;
}
</style>
